import Web3 from 'web3';
import { Contract } from 'web3-eth-contract';
import AirdropManagerContract from '@/common/contracts/abis/airdrop-manager';

export function getAirdropManagerContract(web3: Web3 | null, chainId: number): Contract | null {
  const address = AirdropManagerContract.address.get(chainId);
  if (web3 === null) {
    return null;
  }
  return new web3.eth.Contract(AirdropManagerContract.ABI, address);
}

/**
 * 获取当前AirDrop的list
 * @param contract
 */
export async function getAirdropList(contract: Contract): Promise<string[]> {
  console.log('run ....');
  const length = await contract.methods.getAirdropsLength().call();
  console.log(`length is ${length}`);
  const result = [] as string[];
  for (let i = 0; i < length; i += 1) {
    const address = await contract.methods.getAirdropAddress(i).call();
    result.push(address);
  }
  return result;
}
