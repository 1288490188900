import Web3 from 'web3';
import { Contract } from 'web3-eth-contract';
import Airdrop from '@/common/contracts/abis/airdrop';

export function getAirdropContract(web3: Web3 | null, address: string): Contract | null {
  if (web3 === null) {
    return null;
  }
  return new web3.eth.Contract(Airdrop.ABI, address);
}

/**
 * 获取AirDrop的title
 * @param contract
 */
export async function getAirdropTitle(contract: Contract): Promise<string> {
  return contract.methods.title().call();
}

/**
 * 判断account是否在whitelist中
 * @param contract
 * @param account
 */
export async function isInWhitelist(contract: Contract, account: string): Promise<boolean> {
  return contract.methods.whitelist(account).call();
}

/**
 * 判断用户是否已经领取了
 * @param contract
 * @param account
 */
export async function isClaimed(contract: Contract, account: string): Promise<boolean> {
  return contract.methods.claimed(account).call();
}

/**
 * claim 空投
 * @param contract
 * @param from
 */
export async function claim(contract: Contract, from: string): Promise<boolean> {
  try {
    await contract.methods.claim().send({ from });
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
}
