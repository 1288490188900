
import {
  defineComponent, onMounted, reactive, ref, watch,
} from 'vue';
import { useStore } from '@/store';
import {
  claim,
  getAirdropContract,
  getAirdropTitle,
  isClaimed,
  isInWhitelist,
} from '@/common/contracts/apis/airdrop-api';

export default defineComponent({
  props: {
    address: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const contract = getAirdropContract(store.state.web3, props.address);

    const data = reactive({
      title: '',
      inWhitelist: false,
      isClaimed: false,
    });

    const checkWhitelist = async () => {
      if (contract !== null && store.state.currentAccount !== undefined) {
        isInWhitelist(
          contract, store.state.currentAccount,
        ).then((result) => {
          data.inWhitelist = result;
        });
      }
    };

    const fetchAirdropTitle = async () => {
      if (contract !== null) {
        getAirdropTitle(contract).then((result) => {
          console.log(`the title is ${result}`);
          data.title = result;
        });
      }
    };

    const checkIsClaimed = async () => {
      if (contract != null && store.state.currentAccount !== undefined) {
        isClaimed(contract, store.state.currentAccount).then((result) => {
          data.isClaimed = result;
        });
      }
    };

    const claimToken = async () => {
      if (contract !== null && store.state.currentAccount !== undefined) {
        claim(contract, store.state.currentAccount).then((result) => {
          data.isClaimed = result;
        });
      }
    };

    const refreshPage = () => {
      checkWhitelist();
      fetchAirdropTitle();
      checkIsClaimed();
    };

    onMounted(() => {
      refreshPage();
    });

    watch([
      () => store.state.web3,
      () => store.state.currentAccount,
      () => store.state.currentChainId], () => {
      refreshPage();
    });

    return {
      props,
      data,
      claimToken,
    };
  },
});
