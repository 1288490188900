export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "aridropRound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NECO Token Aridrop Round"])},
        "expireTime:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expire Time"])},
        "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Date"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
        "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Network"])},
        "notInList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are not in PrivateSale whitelist."])},
        "assignedAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You assigned amount"])},
        "getDrop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get Drop"])}
      },
      "zh": {
        "publicSale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NECO Token 空投"])},
        "expireTime:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["到期时间"])},
        "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开始日期"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["价格"])},
        "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["网络"])},
        "notInList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您还未在私售白名单内。"])},
        "assignedAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您可以购买"])},
        "getDrop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["获取空投"])}
      }
    }
  })
}
